import axiosRequest from '@/misc/apiService'

const modUnit = {
  namespaced: true,

  state: {
    units: [],
    unit: null
  },

  getters: {},

  mutations: {
    setUnits (state, payload) {
      state.units = payload
    },
    setUnit (state, payload) {
      state.unit = payload
    }

  },

  actions: {
    getUnits ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/units'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            console.log(data);
            commit('setUnits', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createUnit ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'units',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteUnitById ({ commit }, unitId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `units/${unitId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getUnitById ({ commit }, unitId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `units/${unitId}`
        })
          .then((data) => {
            commit('setUnit', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateUnitById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/units/${payload.unitId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modUnit
