import axiosRequest from '@/misc/apiService'

const modParameter = {
  namespaced: true,

  state: {
    parameters: [],
    parameter: null
  },

  getters: {},

  mutations: {
    setParameters (state, payload) {
      state.parameters = payload
    },
    setParameter (state, payload) {
      state.parameter = payload
    }

  },

  actions: {
    getParameters ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload?? {}
        let url = '/parameters'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setParameters', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createParameter ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'parameters',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    uploadFile ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'parameters/upload',
          params: params,
          options: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    deleteParameterById ({ commit }, parameterId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `parameters/${parameterId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getParameter ({ commit }, name) {
      return new Promise((resolve, reject) => {
        const params = {name: name}
        axiosRequest({
          method: 'get',
          params: params,
          url: `parameters/by-name`
        })
          .then((data) => {
            //commit('setParameter', data)
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getParameterById ({ commit }, parameterId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `parameters/${parameterId}`
        })
          .then((data) => {
            commit('setParameter', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateParameterById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/parameters/${payload.parameterId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modParameter
