import axiosRequest from '@/misc/apiService'

const modOrder = {
  namespaced: true,

  state: {
    orders: [],
    order: null,
    sales: [],
    orderGroups: [],
    orderGroup: null
  },

  getters: {},

  mutations: {
    setSales (state, payload) {
      state.sales = payload
    },
    setOrders (state, payload) {
      state.orders = payload
    },
    setOrder (state, payload) {
      state.order = payload
    },
    setOrderGroups (state, payload) {
      state.orderGroups = payload
    },
    setOrderGroup (state, payload) {
      state.orderGroup = payload
    }

  },

  actions: {
    getOrders ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/orders'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setOrders', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getSales ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload;
        let url = '/orders/sales'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setSales', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getGroups ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/order-groups'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setOrderGroups', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    sendOrder ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'orders',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    deleteOrderById ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `orders/${orderId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getOrderById ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `orders/${orderId}`
        })
          .then((data) => {
            console.log(data)
            commit('setOrder', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateOrderById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/orders/${payload.orderId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }




  }
}

export default modOrder
