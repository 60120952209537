<template>
  <div>
    <Dashboard></Dashboard>
    <Navigation></Navigation>
  </div>
</template>

<script>
import Dashboard from "../components/Dashboard";
import Navigation from "../components/Navigation";

export default {
  name: "Main",
  components: {
    Dashboard,
    Navigation
  }
}
</script>

<style scoped>

</style>