import axiosRequest from '@/misc/apiService'

const modSupplier = {
  namespaced: true,

  state: {
    suppliers: [],
    supplier: null
  },

  getters: {},

  mutations: {
    setSuppliers (state, payload) {
      state.suppliers = payload
    },
    setSupplier (state, payload) {
      state.supplier = payload
    }

  },

  actions: {
    getSuppliers ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/suppliers'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setSuppliers', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createSupplier ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'suppliers',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteSupplierById ({ commit }, supplierId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `suppliers/${supplierId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getSupplierById ({ commit }, supplierId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `suppliers/${supplierId}`
        })
          .then((data) => {
            commit('setSupplier', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateSupplierById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/suppliers/${payload.supplierId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modSupplier
