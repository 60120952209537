import Cookies from 'js-cookie'
import axiosRequest from '@/misc/apiService'
import axios from 'axios'
import { CookiesAuthName } from '../../misc/Constants/constants'
import bcrypt from 'bcryptjs'
import { uuid } from 'vue-uuid'

const modCore = {
  namespaced: true,

  state: {
    token: null,
    authenticated: false,
    preorder: [],
    currentUser: null,
    currentEmployee: null,
    currentLocation: null,
    showMapOrList: false,
    currentCategory: null,
    currentProduct: null,
    currentPrinter: null,
    currentSupplier: null,
    currentSupplierOrder: null,
    currentSupplierOrderDraft: null,
    showMessages: false,
    showVariations: false,
    addVariations: true,
    accessLevel: -1,
    user: {},
    users: []
  },

  getters: {},

  mutations: {
    setUser (state, payload) {
      state.user = payload
    },

    setUsers (state, payload) {
      state.users = payload
    },

    setCurrentCategory (state, payload) {
      state.currentCategory = payload
      //console.log(state.currentCategory)
    },

    setCurrentEmployee (state, payload) {
      state.currentEmployee = payload
      //console.log(state.currentCategory)
    },

    setCurrentEmployeeHours (state, payload) {
      state.currentEmployeeHours = payload
    },

    setCurrentProduct (state, payload) {
      state.currentProduct = payload
    },

    setCurrentPrinter (state, payload) {
      state.currentPrinter = payload
    },

    setCurrentSupplier (state, payload) {
      state.currentSupplier = payload
    },

    setCurrentSupplierOrder (state, payload) {
      state.currentSupplierOrder = payload
    },

    setCurrentSupplierOrderDraft (state, payload) {
      state.currentSupplierOrderDraft = payload
    },

    setCurrentLocation (state, payload) {
      state.currentLocation = payload
    },

    setShowMapOrList (state, payload) {
      state.showMapOrList = payload
    },

    setToken (state, payload) {
      state.token = payload
      axios.defaults.headers.common['Authorization'] = state.token
    },

    setAuthenticatedUser (state, payload) {
      state.currentUser = payload
      if (payload!=null)
        state.accessLevel = payload.level
      else {
        state.accessLevel = -1
      }
    }
    ,

    setAuthenticationStatus (state, status) {
      state.authenticated = status
    },

    setShowMessages (state, payload) {
      state.showMessages = payload
    },

    setShowVariations (state, payload) {
      state.showVariations = payload
    },

    setAddVariations (state, payload) {
      state.addVariations = payload
    }
  },

  actions: {

    getAuthenticationStatus ({ dispatch, commit }) {
      const token = Cookies.get(CookiesAuthName)
      const authenticationStatus = Boolean(token)
      dispatch('updateAuthenticationStatus', authenticationStatus)
      console.log('auth status ' + authenticationStatus);
      if (authenticationStatus) {
        commit('setToken', token)
        dispatch('getAuthenticatedUser')
      }
    },

    updateAuthenticationStatus ({ commit }, status) {
      commit('setAuthenticationStatus', status)
    },

    storeToken (context, token) {
      context.commit('setToken', token)
      Cookies.set(CookiesAuthName, token)
    },

    removeToken (context) {
      context.commit('setToken', null)
      context.commit('setAuthenticatedUser', null)
      Cookies.remove(CookiesAuthName)
    },

    authCheck ({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        const params = {
          username: credentials.login,
          password: credentials.password
        }
        axiosRequest({
          method: 'post',
          url: '/auth/login',
          params: params
        })
          .then((data) => {
            if (!data.error) {
              dispatch('storeToken', data.authenticationToken)
              dispatch('updateAuthenticationStatus', true)
              dispatch('getAuthenticatedUser')
            }
            else {
              dispatch('storeToken', null)
              dispatch('updateAuthenticationStatus', false)
            }
            resolve(data);
          })
          .catch((err) => reject(err))
      })
    },

    getAuthenticatedUser ({ commit }) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: 'auth/user'
        })
          .then(data => {
            commit('setAuthenticatedUser', data)
            resolve(data.level)
          })
          .catch(err => reject(err))
      })
    },

    getUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: '/users'
        })
          .then((data) => {
            const users = data
            commit('setUsers', users)
            resolve(users)
          })
          .catch((err) => reject(err))
      })
    },

    createUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {
          name: payload.name,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          level: payload.level,
          authenticationToken: uuid.v4()
        }
        if (payload.password !== '') {
          params.passwordHash = bcrypt.hashSync(payload.password, bcrypt.genSaltSync(10))
        }
        axiosRequest({
          method: 'post',
          url: 'users',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    getUserById ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `users/${userId}`
        })
          .then((data) => {
            commit('setUser', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateUserById ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {
          id: payload.userId,
          name: payload.name,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          level: payload.level
        }
        if (payload.password !== '') {
          params.passwordHash = bcrypt.hashSync(payload.password, bcrypt.genSaltSync(10))
        }
        axiosRequest({
          method: 'put',
          url: '/users/' + payload.userId,
          params: params
        })
          .then(() => resolve())
          .catch(err => reject(err))
      })
    },

    deleteUserById ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `users/${userId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    logout ({ dispatch }) {
      return new Promise((resolve) => {
        console.log('logging out');
        const authenticationStatus = false;
        this.currentUser = null;
        dispatch('updateAuthenticationStatus', authenticationStatus);
        dispatch('removeToken');
        axios.defaults.headers.common['Authorization'] = null;
        resolve();
      })
    }
  }
}

export default modCore