import axiosRequest from '@/misc/apiService'

const modProduct = {
  namespaced: true,

  state: {
    products: [],
    productTypes: [],
    product: null,
    variations: [],
    variation: null
  },

  getters: {},

  mutations: {
    setProducts (state, payload) {
      state.products = payload
    },
    setProduct (state, payload) {
      state.product = payload
    },

    setVariations (state, payload) {
      state.variations = payload
    },
    setVariation (state, payload) {
      state.variation = payload
    },

    setProductTypes (state, payload) {
      state.productTypes = payload
    },



  },

  actions: {
    getProductTypes ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/product-types'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setProductTypes', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getInventoryList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/products/inventory'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setProducts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getProductsWithSuppliers ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/products/with-suppliers'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getProducts ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/products'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setProducts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    inventoryUpdate ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'products/inventory',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    getVariations ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        if (!payload.typeId || payload.typeId!=5) {
          payload.typeId = 5;
        }
            let url = '/products'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setVariations', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createProduct ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'products',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteProductById ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `products/${productId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getCost ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `products/${productId}/cost`
        })
          .then((data) => {
            //commit('setProduct', data)
            resolve(data.result)
          })
          .catch((err) => reject(err))
      })
    },

    getProductById ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `products/${productId}`
        })
          .then((data) => {
            commit('setProduct', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateProductById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/products/${payload.productId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    materialsBill ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/products/materialsBill'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setProducts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },





  }
}

export default modProduct
