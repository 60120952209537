<template>
  <div>
    <Navigation></Navigation>
    <WorkshiftsCalendar @showUnavail="showUnavailabilities=true" v-if="!showUnavailabilities"></WorkshiftsCalendar>
    <WorkshiftsUnavailabilities @hideUnavail="showUnavailabilities=false" v-if="showUnavailabilities"></WorkshiftsUnavailabilities>
    <WorkshiftsStamp @stamped="updateHistory" :class="{hidden: activeComponent != 'stamper'}"></WorkshiftsStamp>
    <WorkshiftsHistory ref="history" :class="{hidden: activeComponent != 'list'}"></WorkshiftsHistory>
    <button :class="activeComponent == 'calendar'?'active':''" class="calendar-toggler" @click="activeComponent = 'calendar'"><i class="fal fa-calendar-days"></i></button>
    <button :class="activeComponent == 'stamper'?'active':''" class="stamp-toggler" @click="activeComponent = 'stamper'"><i class="fal fa-stamp"></i></button>
    <button :class="activeComponent == 'list'?'active':''" class="history-toggler" @click="activeComponent = 'list'"><i class="fal fa-history"></i></button>
    <!--button v-if="currentUser?.level <= 2" :class="activeComponent == 'list'?'active':''" class="unavailabilities-toggler" @click="showUnavailabilities = !showUnavailabilities"><i class="fal fa-umbrella-beach"></i></button-->
  </div>
</template>

<script>
import WorkshiftsCalendar from "../components/WorkshiftsCalendar";
import WorkshiftsStamp from "../components/WorkshiftsStamp.vue";
import WorkshiftsHistory from "../components/WorkshiftsHistory.vue";
import Navigation from "../components/Navigation";
import WorkshiftsUnavailabilities from "@/components/WorkshiftsUnavailabilities.vue";
import { mapState } from "vuex";

export default {
  name: "Workshifts",
  data() {
    return {
      activeComponent: 'calendar',
      showUnavailabilities: false,
    }
  },
  components: {
    WorkshiftsUnavailabilities,
    WorkshiftsCalendar,
    WorkshiftsStamp,
    WorkshiftsHistory,
    Navigation
  },
  methods: {
    updateHistory() {
      this.$refs.history.loadHours();
    }
  },

  computed: {
    ...mapState('core', ['currentUser'])
  }
}
</script>

<style scoped>

</style>