<template>
  <div id="dashboard" v-if="currentUser">
    <div class="content main box" v-if="currentUser.employee">

      <div class="page-tools container">
        <h1>Ciao {{ currentUser.employee.nickname }}</h1>
      </div>
      <Summary></Summary>
      <button class="go-to-calendar" @click="$router.push('/workshifts')"><i class="fa-light fa-calendar-days"></i> vai al calendario</button>

    </div>
    <div class="content main box" v-else>
      <div class="page-tools container">
        <p>questa app è riservata ai collaboratori</p>
      </div>
    </div>
  </div>
</template>

<script>
import Summary from "@/components/Summary.vue";
import {mapActions, mapState, mapMutations} from "vuex";
import rgbHex from 'rgb-hex';

export default {
  name: "Dashboard",
  components: {
    Summary
  },
  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
    ...mapActions({
    }),
  },

  computed: {
    ...mapState('core', ['currentUser'])
  }

}
</script>

<style scoped>

</style>