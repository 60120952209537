import axiosRequest from '@/misc/apiService'

const modEmployee = {
  namespaced: true,

  state: {
    employees: [],
    employee: null
  },

  getters: {},

  mutations: {
    setEmployees (state, payload) {
      state.employees = payload
    },
    setEmployee (state, payload) {
      state.employee = payload
    }

  },

  actions: {
    getEmployees ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/employees'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setEmployees', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getHours ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/employees/hours'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            //commit('setEmployees', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    stamp ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'employees/stamp',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getUnavailabilities ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/employees/unavailabilities'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            //commit('setEmployees', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },



    createUnavailability ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'employees/unavailabilities',
          params: params
        })
          .then((data) => {
            resolve(data.newItem)
          })
          .catch((err) => reject(err))
      })
    },

    deleteUnavailability ({ commit }, key) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `employees/unavailabilities/${key}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createEmployee ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'employees',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteEmployeeById ({ commit }, employeeId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `employees/${employeeId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getEmployeeById ({ commit }, employeeId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `employees/${employeeId}`
        })
          .then((data) => {
            commit('setEmployee', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateEmployeeById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/employees/${payload.employeeId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modEmployee
