import axiosRequest from '@/misc/apiService'

const modWhatsapp = {
  namespaced: true,

  state: {
    whatsapps: [],
    whatsapp: null
  },

  getters: {},

  mutations: {
    setWhatsapps (state, payload) {
      state.whatsapps = payload
    },
    setWhatsapp (state, payload) {
      state.whatsapp = payload
    }

  },

  actions: {

    send ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'whatsapp/send',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

  }
}

export default modWhatsapp
