import axiosRequest from '@/misc/apiService'

const modCustomer = {
  namespaced: true,

  state: {
    customers: [],
    customer: null
  },

  getters: {},

  mutations: {
    setCustomers (state, payload) {
      state.customers = payload
    },
    setCustomer (state, payload) {
      state.customer = payload
    }

  },

  actions: {
    getCustomers ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/customers'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setCustomers', data.rows.length ? Object.values(data.rows) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createCustomer ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'customers',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteCustomerById ({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `customers/${customerId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getCustomerById ({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `customers/${customerId}`
        })
          .then((data) => {
            commit('setCustomer', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateCustomerById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/customers/${payload.customerId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modCustomer
