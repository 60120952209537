<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    window.addEventListener('resize', this.resetUnitsAndSize);
    this.resetUnitsAndSize();
  },
  methods: {
    resetUnitsAndSize() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
}
</script>

<style>
</style>
