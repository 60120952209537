import axiosRequest from '@/misc/apiService'

const modSupplierOrder = {
  namespaced: true,

  state: {
    supplierOrders: [],
    supplierOrder: null,
    supplierOrderDrafts: [],
    supplierOrderDraft: null
  },

  getters: {},

  mutations: {
    setSupplierOrders (state, payload) {
      state.supplierOrders = payload
    },
    setSupplierOrder (state, payload) {
      state.supplierOrder = payload
    },

    setSupplierOrderDrafts (state, payload) {
      state.supplierOrderDrafts = payload
    },
    setSupplierOrderDraft (state, payload) {
      state.supplierOrderDraft = payload
    },


  },

  actions: {
    getSupplierOrders ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/supplier-orders'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setSupplierOrders', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getSupplierOrderDrafts ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/supplier-orders/drafts'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setSupplierOrderDrafts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createSupplierOrder ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'supplier-orders',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    createSupplierOrderDraft ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'supplier-orders/drafts',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteSupplierOrderById ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `supplier-orders/${orderId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getCost ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `orders/${orderId}/cost`
        })
          .then((data) => {
            //commit('setOrder', data)
            resolve(data.result)
          })
          .catch((err) => reject(err))
      })
    },

    getOrderById ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `orders/${orderId}`
        })
          .then((data) => {
            commit('setOrder', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateOrderById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/orders/${payload.orderId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    updateSupplierOrderDraftById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/supplier-orders/drafts/${payload.id}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    materialsBill ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/orders/materialsBill'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setOrders', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },





  }
}

export default modSupplierOrder
