import axiosRequest from '@/misc/apiService'

const modAlarm = {
  namespaced: true,

  state: {
    alarms: [],
    alarm: null
  },

  getters: {},

  mutations: {
    setAlarms (state, payload) {
      state.alarms = payload
    },
    setAlarm (state, payload) {
      state.alarm = payload
    }

  },

  actions: {
    getAlarms ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/alarms'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setAlarms', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createAlarm ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'alarms',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteAlarmById ({ commit }, alarmId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `alarms/${alarmId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getAlarmById ({ commit }, alarmId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `alarms/${alarmId}`
        })
          .then((data) => {
            commit('setAlarm', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateAlarmById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/alarms/${payload.id}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modAlarm
