import axiosRequest from '@/misc/apiService'

const modPrinter = {
  namespaced: true,

  state: {
    printers: [],
    printer: null
  },

  getters: {},

  mutations: {
    setPrinters (state, payload) {
      state.printers = payload
    },
    setPrinter (state, payload) {
      state.printer = payload
    }

  },

  actions: {
    getPrinters ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = {}
        let url = '/printers'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setPrinters', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createPrinter ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'printers',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deletePrinterById ({ commit }, printerId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `printers/${printerId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getPrinterById ({ commit }, printerId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `printers/${printerId}`
        })
          .then((data) => {
            commit('setPrinter', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    testById ({ commit }, printerId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `printers/${printerId}/test`
        })
          .then((data) => {
//            commit('setPrinter', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updatePrinterById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/printers/${payload.id}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modPrinter
