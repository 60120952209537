<template>
  <div class="content main box" id="workshifts-stamp">
    <div v-show="geolocationGranted" id="stamper">
      <button class="btn btn-lg btn-secondary" v-if="distance(position,workPosition) > maxDistance"><i class="fal fa-location-dot-slash"></i> {{ position.latitude == 0 ? 'Attendi':'sei troppo lontano'}}</button>
      <button v-else :disabled="stamping" class="btn btn-lg btn-primary" @click="sendStamp"><i :class="stamping?'fa-spin fa-arrows-rotate':'fa-stamp'" class="fal"></i>
        {{ stamping ? 'attendere...': 'timbra' /* + currentUser?.employee?.lastName */ }}</button>
      <span>{{ place }}</span>
      <span v-if="position.latitude == 0"><i class="fal fa-spin fa-arrows-rotate"></i> Attendere<span class="stamper_close">Rilevamento posizione in corso...</span></span>
      <span v-else>distanza: <span :class="{stamper_far_away:distance(position, workPosition)>maxDistance,stamper_close:distance(position, workPosition)>maxDistance<=maxDistance}">{{ distance(position, workPosition) > 1000 ? (distance(position, workPosition)/1000).toFixed(2).replace('.',',') + " km" : distance(position, workPosition).toFixed(0) + " m" }}</span></span>
    </div>
    <div v-show="!geolocationGranted" id="ask-location">
      <div class="alert alert-danger" role="alert">
        <h2 style="text-align: center"><i class="fal fa-triangle-exclamation"></i><i class="fal fa-triangle-exclamation"></i><i class="fal fa-triangle-exclamation"></i> ATTENZIONE <i class="fal fa-triangle-exclamation"></i><i class="fal fa-triangle-exclamation"></i><i class="fal fa-triangle-exclamation"></i></h2>
        Per usare il nuovo sistema di timbratura è NECESSARIO consentire la lettura della posizione del proprio telefono.
        Premi il pulsante qui sotto, poi acconsenti alla lettura della posizione quando richiesto e attendi la comparsa del pulsante di timbratura.
      </div>
      <button class="btn btn-primary" @click="askPosition"><i class="fal fa-location-dot"></i> attiva permesso</button>
    </div>
    <div v-if="showResult" class="stamper-result">
      <div :class="resultError?'result-error':'result-ok'">
        <div class="thumb"><i class="fa-thin fa-2xl" :class="resultError?'fa-exclamation-triangle':'fa-thumbs-up'"></i></div>
        <div class="title">{{ resultError? "attenzione!":"tutto ok" }}</div>
        <div v-if="!resultError" class="icon"><i :class="entering?'fa-person-to-portal':'fa-person-from-portal'" class="fal fa-xl"></i></div>
        <div class="text">{{ resultMessage }}</div>
        <button class="btn" :class="resultError?'btn-secondary':'btn-primary'" @click="stamping = showResult = false">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import employee from "@/store/modules/employee";
export default {
  name: "WorkshiftsStamp",
  data() {
    return {
      lastUpdate: Date.now()-3600000,
      maxDistance: 1, // meters
      position: {
        longitude: 0,
        latitude: 0,
        accuracy: 0
      },
      workPosition: {
        latitude: 0,
        longitude: 0
      },
      stamping: false,
      geolocationGranted: false,
      showResult: false,
      resultError: false,
      resultMessage: '',
      place: 'Luogo di lavoro',
      entering: true,
      stateClass: 'hidden'
    }
  },

  created() {
    this.updatePosition();
  },

  mounted() {
    (async () => {
      let result = await navigator.permissions.query({ name: 'geolocation' });
      this.geolocationGranted = (result.state == "granted");
    })();

    this.updatePosition();
  },

  methods: {
    ...mapActions({
      getParameter: 'parameter/getParameter',
      stamp: 'employee/stamp'
    }),
    ...mapMutations('core', ['setCurrentWorkshift','setCurrentLocation']),

    updatePosition() {


      if (Date.now() - this.lastUpdate > 1800000) {
        (async () => {
          let param = (await this.getParameter('stamper_lat_lng')).value.split(',');
          this.workPosition.latitude = parseFloat(param[0]);
          this.workPosition.longitude = parseFloat(param[1]);
        })();

        (async () => {
          this.maxDistance = parseFloat((await this.getParameter('stamper_max_distance')).value);
        })();

        (async () => {
          this.place = (await this.getParameter('own_company_friendly_name'))?.value??'Luogo di lavoro';
        })();

        this.lastUpdate = Date.now();

      }


      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };

      let comp = this;
      let compPosition = this.position;

      function success(pos) {

        const crd = pos.coords;
        console.log(pos);

        compPosition.latitude = pos.coords.latitude;
        compPosition.longitude = pos.coords.longitude;
        compPosition.accuracy = pos.coords.accuracy;

      }

      function error(err) {
        console.log(`ERROR(${err.code}): ${err.message}`);
        setTimeout(comp.updatePosition, 1);
      }

      if (this.geolocationGranted)
        navigator.geolocation.watchPosition(success, error, options);
      else {
        console.log("scheduling geolocation permission check")
        //setTimeout(this.updatePosition, 3000);
      }

    },

    askPosition() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      let comp = this;
      let compPosition = this.position;

      function success(pos) {

        const crd = pos.coords;
        compPosition.latitude = pos.coords.latitude;
        compPosition.longitude = pos.coords.longitude;
        compPosition.accuracy = pos.coords.accuracy;
        comp.geolocationGranted = true;
        comp.updatePosition();
        console.log(comp.employee);
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);

    },

    async updatePin() {
      let pin = (await this.getParameter('stamper_pin')).value;
    },

    sendStamp() {
      this.stamping = true;
      if(this.currentUser?.employee)
        this.stamp({employeeId: this.currentUser.employee.id}).then(data=>{
          if (data.result == 'ok') {
            this.$emit('stamped');
            this.resultError = false;
            if (data.end) {
              this.entering = false;
              this.resultMessage = 'Uscita registrata correttamente alle ore ' + new Date(Date.parse(data.stamp)).toLocaleTimeString('it-IT') + '. Ciao e grazie.';
            }
            else {
              this.entering = true;
              this.resultMessage = 'Entrata registrata correttamente alle ore ' + new Date(Date.parse(data.stamp)).toLocaleTimeString('it-IT') + '. Buon lavoro!';
            }
          }
          else {
            this.resultError = true;
            this.resultMessage = data.message;
          }
          //this.stamping = false;
          this.showResult = true;
        }).catch(

        );
    },

    distance(coords1, coords2) {

      let accuracy = coords1.accuracy?? coords2.accuracy?? 0;

      console.log(accuracy);

      const R = 6371e3; // metres
      const φ1 = coords1.latitude * Math.PI/180; // φ, λ in radians
      const φ2 = coords2.latitude * Math.PI/180;
      const Δφ = (coords2.latitude-coords1.latitude) * Math.PI/180;
      const Δλ = (coords2.longitude-coords1.longitude) * Math.PI/180;

      const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

      const d = R * c; // in metres
      return Math.max(d - accuracy, accuracy );





    }

  },

  computed: {
    ...mapState('workshift', ['workshifts']),
//    ...mapState('employee', ['employee']),
    ...mapState('parameters',['parameters']),
    ...mapState('core', ['currentUser','currentWorkshift','currentLocation'])
  }

}
</script>

<style scoped>

</style>