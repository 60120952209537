import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import axios from "axios";

import './assets/scss/app.scss';

//axios.defaults.baseURL = 'http://' + window.location.hostname + ':' + (parseInt(window.location.port) < 8088? '8088':'55008') +'/api/'
//axios.defaults.baseURL = 'https://'+ window.location.hostname + '/api/';
if (window.location.hostname.indexOf('localhost') >= 0 || window.location.hostname.indexOf('192') >= 0 || window.location.hostname.indexOf('185') >= 0){
  axios.defaults.baseURL = 'http://' + window.location.hostname + ':8088/api/';
}
else {
  axios.defaults.baseURL = 'https://'+ window.location.hostname + '/api/';
}
//axios.defaults.baseURL = 'https://ristomatico-server.onrender.com/api/';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

// Check auth
store.dispatch('core/getAuthenticationStatus');



createApp(App).use(store).use(router).mount("#app");
