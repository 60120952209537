import axiosRequest from '@/misc/apiService'

const modLocation = {
  namespaced: true,

  state: {
    locations: [],
    location: null
  },

  getters: {},

  mutations: {
    setLocations (state, payload) {
      state.locations = payload
    },
    setLocation (state, payload) {
      state.location = payload
    }

  },

  actions: {
    getLocations ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/locations'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setLocations', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createLocation ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'locations',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteLocationById ({ commit }, locationId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `locations/${locationId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getLocationById ({ commit }, locationId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `locations/${locationId}`
        })
          .then((data) => {
            commit('setLocation', data)
            resolve(data.response)
          })
          .catch((err) => {
            reject(err);
          })
      })
    },

    updateLocationById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/locations/${payload.locationId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modLocation
