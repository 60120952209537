<template>
  <div>
    <Authentication></Authentication>
  </div>
</template>

<script>
import Authentication from "../components/Authentication";

export default {
  name: "Login",
  components: {
    Authentication
  }
}
</script>

<style scoped>

</style>