import axiosRequest from '@/misc/apiService'

const modMessage = {
  namespaced: true,

  state: {
    messages: [],
    message: null
  },

  getters: {},

  mutations: {
    setMessages (state, payload) {
      state.messages = payload
    },
    setMessage (state, payload) {
      state.message = payload
    }

  },

  actions: {
    getMessages ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/messages'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setMessages', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createMessage ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'messages',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteMessageById ({ commit }, messageId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `messages/${messageId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getMessageById ({ commit }, messageId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `messages/${messageId}`
        })
          .then((data) => {
            commit('setmessage', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateMessageById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/messages/${payload.messageId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modMessage
