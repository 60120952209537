import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Main from "../views/Main.vue";
import Warehouse from "../views/Warehouse.vue";
import Workshifts from "../views/Workshifts.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    meta: {
      requiresAuth: true
    },
    component: Main,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    meta: {
      requiresAuth: true
    },
    component: Warehouse,
  },
  {
    path: "/workshifts",
    name: "Workshifts",
    meta: {
      requiresAuth: true
    },
    component: Workshifts,
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      requiresAuth: true
    },
    component: Main,
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history'
});

import modCore from '@/store/modules/core';
console.log(modCore);


router.beforeEach(async(to, from,next) => {

  //console.log(modCore.state.authenticated);
  console.log(to.query);

  if (to.name != 'Login' && to.meta.requiresAuth && !modCore.state.authenticated) {
    console.log('go to login');
    next({name:'Login'});
    //next();
  }
  else next();
});

export default router;
