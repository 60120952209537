<template>
  <div class="content main box" id="workshifts-history">

<!--    <div class="page-tools container">
      <div class="row">
        <div>{{ employee.lastName }}</div>
      </div>
    </div>-->
    <div class="table-wrapper">
      <table class="items-list table table-hover table-striped table-autofit">
        <thead>
          <tr>
            <th>giorno</th>
            <th>inizio</th>
            <th>fine</th>
            <th>ore</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in shifts">
            <td>{{ new Date(item.startTime).toLocaleDateString('it-IT') }}</td>
            <td>{{ getTime(item.startTime)}}</td>
            <td>{{ getTime(item.endTime) }}</td>
            <td>{{ item.endTime?(new Date(item.endTime)-new Date(item.startTime))/3600000:''}}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "WorkshiftsHistory",
  data() {
    return {
      shifts: []
    }
  },

  created() {
    this.loadHours();
  },

  mounted() {
    this.loadHours();
  },

  methods: {
    ...mapActions({
      getHours: 'employee/getHours'
    }),

    getTime(date) {
      let timeObj = new Date(date);
      if(timeObj.getTimezoneOffset()==-60)
        return timeObj.toLocaleTimeString('it-IT').substring(0,5);
      else {
        timeObj = new Date(timeObj.getTime() - 3600*1000);
        return timeObj.toLocaleTimeString('it-IT').substring(0,5);
      }
    },

    getDate(date) {
      let timeObj = new Date(date);
      if(timeObj.getTimezoneOffset()==-60)
        return timeObj.toLocaleDateString('it-IT');
      else {
        timeObj = new Date(timeObj.getTime() - 3600*1000);
        return timeObj.toLocaleDateString('it-IT');
      }
    },

    loadHours() {
      if (!this.currentUser?.employee) {
        setTimeout(this.loadHours, 2000);
        console.log('loadHours waiting');
        return;
      }
      let payload = {
          employeeId: this.currentUser.employee.id
      };
      payload.endTime = new Date(Date.now() + 1800*1000);
      payload.startTime = new Date(Date.now() - 62 * 24 * 3600 * 1000);

      this.getHours(payload)
        .then(
          (data) => {
            if (data.length == 0)
              this.shifts = [];
            else {
              //console.log(data[0]);
              this.shifts = data[0].employeeHours.reverse();
            }
          }

        )
        .catch(

        );
    },

  },

  computed: {
    ...mapState('core', ['currentUser']),
    ...mapState('employee', ['employee'])
  }

}
</script>

<style scoped>

</style>