<template>
  <div class="dashboard-cards" id="summary">
    <p class="loader" v-if="processing"><div><i class="fa-solid fa-spinner fa-pulse"></i></div></p>
    <div v-if="shifts.length > 0" class="card dashboard-card">
      <h3>Prossimo turno</h3>
      <h2>{{ when(shifts[0]) }}</h2>
      <p>servizio: {{ shifts[0].role.name }}</p>
    </div>
    <div v-if="employeeHours.length > 0" class="card dashboard-card">
      <h3>Ultimo turno effettuato</h3>
      <h2>{{ lastWorked().text }}</h2>
      <p>ore svolte: {{ lastWorked().time }}</p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "Summary",
  data() {
    return {
      processing: true,
      euro: Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      }),
      now: Date.now(),
      refreshId: null,
      employeeHours: [],
      shifts: [],
      weekCost: [],
      //startDate: new Date(Date.parse((new Date(Date.now() - (168+15)*3600*1000)).toISOString().split('T')[0]+"T04:00:00.000z")),
      //endDate: new Date((new Date(Date.parse((new Date(Date.now() - 15*3600*1000)).toISOString().split('T')[0]+"T04:00:00.000z"))).getTime() + 24*3600*1000),
      showInactive: false,
      stateClass: 'hidden'
    }
  },

  created() {
    this.loadHours();
  },

  mounted() {
    this.loadHours();
    this.refreshId = setInterval(this.updateTime, 1000);
  },

  unmounted() {
    clearInterval(this.refreshId);
  },

  methods: {
    ...mapActions({
      getHours: 'employee/getHours',
      getWorkshifts: 'workshift/getWorkshifts'
    }),
    ...mapMutations('core', ['setCurrentEmployee','setCurrentLocation']),

    lastWorked(){
      let result = {text:'', time:''};
      if (this.employeeHours.length > 0) {
        let last = this.employeeHours[this.employeeHours.length - 1];
        if (last.endTime == null) {
          result.text = "in corso dalle " + new Date(last.startTime).toTimeString().substring(0,5);
          result.time = new Date(this.now-new Date(last.startTime).getTime()).toTimeString().substring(0,8);//Math.floor((this.now - new Date(last.startTime) )/1800000)*.5;
        }
        else {
          result.time = new Date(Date.parse(last.endTime) - Date.parse(last.startTime))/3600000; //Math.floor((this.now - new Date(last.startTime) )/1800000)*.5;
          let dayDiff = new Date(this.now);
          dayDiff.setHours(3,0,0,0);
          dayDiff = new Date(dayDiff - Date.parse(last.startTime.substring(0,10))).getDate();
          let actualDay = "";
          switch (dayDiff) {
            case 1: actualDay = "oggi"; break;
            case 2: actualDay = "ieri"; break;
            default: actualDay = new Date(last.startTime).toLocaleDateString('it-IT');
          }
          actualDay += " (" + this.getTime(new Date(last.startTime));
          actualDay += "-" + this.getTime(new Date(last.endTime)) + ")";
          result.text = actualDay;
        }

      }
      return result;
    },

    getTime(date) {
      let timeObj = new Date(date);
      if(timeObj.getTimezoneOffset()==-60)
        return timeObj.toLocaleTimeString('it-IT').substring(0,5);
      else {
        timeObj = new Date(timeObj.getTime() - 3600*1000);
        return timeObj.toLocaleTimeString('it-IT').substring(0,5);
      }
    },


    when(shift) {
      let result = "";
      let diff = shift.startTime - this.now;
      if (diff < 3600*1000*4 ) {
        let timeObj = new Date(diff - 3600*1000);
        if(timeObj.getTimezoneOffset()==-60) {}
        else {
          timeObj = new Date(timeObj.getTime() - 3600*1000);
        }
        result = "fra " + new Date(timeObj).toTimeString().substring(0,8);
      }
      else {
        let dayDiff = (new Date(this.now)).getDate() - (shift.startTime.getDate());
        let actualDay = "";
        switch (dayDiff) {
          case 0: actualDay = "oggi"; break;
          case 1: actualDay = "domani"; break;
          default: actualDay = shift.startTime.toLocaleDateString('it-IT',{weekday: 'short', day: 'numeric', month: 'short'});
        }
        return actualDay + " alle " + shift.startTime.toLocaleTimeString('it-IT').substring(0,5);
      }
      return result; //shift.startTime.toLocaleDateString('it-IT',{weekday: 'short', day: 'numeric', month: 'short'})
    },

    updateTime() {
      this.now = Date.now();
      if ( (Math.floor(this.now/1000) % 60) == 0) {
        this.loadHours();
      }
    },

    loadHours() {
      let payload = {
        employeeId: this.currentUser.employee.id
      };
      this.processing = true;
      this.getHours(payload)
        .then(
          (data) => {
            this.employeeHours = data[0].employeeHours;
            payload.startTime = new Date().toISOString();
            this.getWorkshifts(payload).then(data=> {
              let items = data;
              items.forEach(el => el.startTime = new Date(Date.parse(el.startTime)));
              items.sort((a,b) => a.startTime > b.startTime? 1:-1);
              this.shifts = items;
              console.log(items);
              this.processing = false;
            });
          }
        )
        .catch(

        );
    }


  },

  computed: {
    ...mapState('employee', ['employees']),
    ...mapState('core', ['currentUser','currentEmployee']),
    lastShift() {
      let pastShifts = this.employeeHours.filter(el => el.endTime != null);
      if (pastShifts.length > 0) {

      }
     }
  },

  watch: {
    // whenever question changes, this function will run
    startDate(newStartDate, oldStartDate) {
      this.loadHours();
    },
    endDate(newEndDate, oldEndDate) {
      this.loadHours();
    },
  }


}
</script>

<style scoped>

</style>