import axiosRequest from '@/misc/apiService'

const modCategory = {
  namespaced: true,

  state: {
    categories: [],
    category: null
  },

  getters: {},

  mutations: {
    setCategories (state, payload) {
      state.categories = payload
    },
    setCategory (state, payload) {
      state.category = payload
    }

  },

  actions: {
    getCategories ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/categories'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setCategories', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createCategory ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'categories',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteCategoryById ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `categories/${categoryId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getCategoryById ({ commit }, categoryId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `categories/${categoryId}`
        })
          .then((data) => {
            commit('setCategory', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateCategoryById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/categories/${payload.categoryId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modCategory
