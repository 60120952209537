import axiosRequest from '@/misc/apiService'

const modSupplierProduct = {
  namespaced: true,

  state: {
    supplierProducts: [],
    supplierProduct: null
  },

  getters: {},

  mutations: {
    setSupplierProducts (state, payload) {
      state.supplierProducts = payload
    },
    setSupplierProduct (state, payload) {
      state.supplierProduct = payload
    },


  },

  actions: {
    getSupplierProducts ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/supplier-products'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setSupplierProducts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createSupplierProduct ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'supplier-products',
          params: params
        })
          .then(() => {
            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    deleteSupplierProductById ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `supplier-products/${productId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getCost ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `products/${productId}/cost`
        })
          .then((data) => {
            //commit('setProduct', data)
            resolve(data.result)
          })
          .catch((err) => reject(err))
      })
    },

    getProductById ({ commit }, productId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `products/${productId}`
        })
          .then((data) => {
            commit('setProduct', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateProductById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/products/${payload.productId}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    materialsBill ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/products/materialsBill'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setProducts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },





  }
}

export default modSupplierProduct
