import { createStore } from "vuex";


import modCore from '@/store/modules/core.js';
import modEmployee from '@/store/modules/employee';
import modLocation from '@/store/modules/location';
import modCategory from '@/store/modules/category';
import modProduct from '@/store/modules/product';
import modUnit from '@/store/modules/unit';
import modOrder from '@/store/modules/order';
import modParameter from '@/store/modules/parameter';
import modPrinter from '@/store/modules/printer';
import modAlarm from '@/store/modules/alarm';
import modMessage from "@/store/modules/message";
import modCustomer from '@/store/modules/customer';
import modSupplier from '@/store/modules/supplier';
import modSupplierOrder from '@/store/modules/supplierOrder';
import modSupplierProduct from '@/store/modules/supplierProduct';
import modWhatsapp from "@/store/modules/whatsapp";
import modWorkshift from "@/store/modules/workshifts";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alarm: modAlarm,
    core: modCore,
    category: modCategory,
    customer: modCustomer,
    employee: modEmployee,
    location: modLocation,
    message: modMessage,
    order: modOrder,
    parameter: modParameter,
    product: modProduct,
    printer: modPrinter,
    supplier: modSupplier,
    supplierOrder: modSupplierOrder,
    supplierProduct: modSupplierProduct,
    unit: modUnit,
    whatsapp: modWhatsapp,
    workshift: modWorkshift
  },
});
