<template>
  <div class="content main box" id="workshifts-calendar">
    <FullCalendar ref="calendar" :options="calendarOptions"></FullCalendar>
  </div>
  <!--div id="employees-box" v-show="currentUser?.level < 3 && currentUser?.name!='luca'">
    <button @click="$emit('showUnavail')"><i class="fal fa-umbrella-beach"></i> mostra ferie</button>
    <div class="role" :key="role.id" v-for="role in roles.filter( r => r.inTimetable)">
      <div class="employees-role">{{role.name}}</div>
      <div :style="{background:role.color}" :data-shift="JSON.stringify( {employeeId: employee.id, role:role, roleId: role.id })" draggable="true" class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-draggable" :key="employee.id" v-for="employee in employees.filter(el => el.roles.findIndex(r => r.id == role.id)>=0 &&  (showInactive? true: el.active == true) ).sort((a,b)=>{return (a.nickname > b.nickname)? 1:-1 })">{{ employee.nickname}}</div>
    </div>
  </div-->
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import employee from "@/store/modules/employee";

var comp;
var businessHours = {
  // days of week. an array of zero-based day of week integers (0=Sunday)
  daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6 ],
  startTime: '08:00', // a start time (10am in this example)
  endTime: '24:00', // an end time (6pm in this example)
};
export default {
  name: "WorkshiftsCalendar",
  components: {
    FullCalendar
  },
  data() {
    return {
      onlyMe: null,
      roles:[],
      showInactive: false,
      events: [],
      eventDisplay: 'block',
      selectedEvents: [],
      calendarOptions: {
        locales: allLocales,
        locale: 'it',
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        },
        businessHours: businessHours,
        eventConstraint: "businessHours",
        //hiddenDays: [2],
        slotMinTime: '16:00:00',
        slotMaxTime: '23:30:00',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        customButtons: {
          sendButton: {
            text: '',
            click: function() {
              comp.sendWorkshifts().then((data)=> {
                console.log(data);
                //comp.calendarOptions.events = comp.events;
              });
            }
          },
          onlyMeButton: {
            text: '',
            click: function() {
              comp.onlyMe = !comp.onlyMe;
              comp.loadWorkshifts();
            }
          },
          swapButton: {
            text: '',
            click: function() {
              if (comp.selectedEvents.length != 2) {
                alert('devi selezionare 2 turni');
              }
            }
          },
          deleteButton: {
            text: '',
            click: function() {
              if (comp.selectedEvents.length > 0) {
                let toDelete = [];
                comp.selectedEvents.forEach(ev => {
                  toDelete.push(ev.id);
                  //comp.selectedEvents.slice(comp.selectedEvents.findIndex(remove => remove.id == ev.id),1);
                });
                comp.selectedEvents = [];
                toDelete.forEach(id => {
                  let idx = comp.events.findIndex(remove => remove.id == id);
                  console.log(idx);
                  comp.events.splice(idx, 1);
                  comp.deleteWorkshift(id).then(()=> {
                    //comp.calendarOptions.events = comp.events;
                  });
                });
              }
            }
          }
        },
        viewClassNames: (value) =>{
          if (value.view.type == 'listWeek') {
            console.log('all events?');
            console.log(comp.allItems);
            comp.events = []; // comp.allItems;
            comp.allItems.forEach(el => {
              comp.events.push(el);
            });
            //comp.events.forEach(el => el.classNames = []);
          }
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'deleteButton onlyMeButton swapButton sendButton dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        events: null,
        eventOrder: (a,b) => {
          if(a.extendedProps.role==undefined) console.log(a);
          if(b.extendedProps.role==undefined) console.log(b);
          if (a.extendedProps.role.id == b.extendedProps.role.id) {
            if (a.start == b.start) {
              return (a.title < b.title ? -1:1);
            }
            else return (a.start < b.start ? -1:1);
          }
          else return (a.extendedProps.role.id < b.extendedProps.role.id ? -1:1);
        },
        editable: true,
        droppable: true, // this allows things to be dropped onto the calendar
        eventClick: info => {
          console.log('eventClick');
          if (comp.currentUser.level>2) return;
          console.log(info);
          if (comp.selectedEvents.find(el => el.id == info.event.id)){
            comp.selectedEvents.splice(comp.selectedEvents.findIndex(el => el.id == info.event.id),1);
            let domEl = info.jsEvent.target;
            while (!domEl.classList.contains('fc-event') && domEl.parentElement!=null)
              domEl = domEl.parentElement;
            domEl.classList.remove('selected');
          }
          else {
            comp.selectedEvents.push(info.event);
            let domEl = info.jsEvent.target;
            while (!domEl.classList.contains('fc-event') && domEl.parentElement!=null)
              domEl = domEl.parentElement;
            domEl.classList.add('selected');
          }
          console.log(comp.selectedEvents);
        },
        /*eventDragStart: function(info) {
          console.log('eventDragStart');
          console.log(info);
          console.log(comp.test);
        },
        eventDragStop: function(info) {
          console.log('eventDragStop');
          console.log(info);
        },*/
        eventDrop: function(info) {
          console.log('eventDrop');
          if (comp.currentUser.level>2) {
            info.revert();
            return;
          }
          console.log(info);
          let payload = {id:info.event.id, startTime: info.event.start};
          comp.updateWorkshift(payload);
        },
        drop: function(info) {
          console.log('drop');
          if (comp.currentUser.level>2){
            return;
          }
          console.log(info);



          //comp.events.push({});
          let payload = JSON.parse(info.draggedEl.getAttribute('data-shift'));
          let newEvent = {display:'block', color: payload.role.color, role:payload.role, id: info.draggedEl.innerText + Date.now(), title: info.draggedEl.innerText + " (" +payload.role.name.toUpperCase().substring(0,1)+")", start: new Date(info.date.getTime() + 19 * 3600 * 1000) };
          payload.startTime = new Date(info.date.getTime() + 19 * 3600 * 1000);

          let employee = comp.employees.find(el => el.id == payload.employeeId);

          let block = false;
          employee.employeeUnavailabilities.forEach(unavailability => {
            let startDate = (new Date(unavailability.startDate + 'T00:00:00.000z')).getTime();
            let endDate = (new Date(unavailability.endDate + 'T23:59:59.999z')).getTime();
            if (startDate <= payload.startTime && endDate >= payload.startTime) {
              block = true;
              alert(employee.nickname + ' non è disponibile in data ' + (new Date(unavailability.startDate + 'T00:00:00.000z')).toLocaleDateString('it-IT'));
            }
          });

          if (block) {
            return;
          }


          comp.createWorkshift(payload).then((data) => {
            newEvent.id = data.id;
            comp.events.push(newEvent);
          });

        }
      },
      oldWorkshifts: [],
      stateClass: 'hidden'
    }
  },

  created() {

    comp = this;

    this.loadWorkshifts();
/*
    if (localStorage.getItem('workshifts') != null ) {
      this.oldWorkshifts = JSON.parse(localStorage.getItem('workshifts'));
    }
    this.getWorkshifts({active:1})
        .then(
            () => {
              //console.log(this.workshifts);
              localStorage.setItem('workshifts',JSON.stringify(this.workshifts));
              this.oldWorkshifts = this.workshifts;
            }
        )
        .catch(

        );

 */

  },

  mounted() {
    this.setupDraggable();
    this.loadWorkshifts();
  },

  methods: {
    ...mapActions({
      getWorkshifts: 'workshift/getWorkshifts',
      createWorkshift: 'workshift/createWorkshift',
      updateWorkshift: 'workshift/updateWorkshiftById',
      deleteWorkshift: 'workshift/deleteWorkshiftById',
      sendWorkshifts: 'workshift/sendAll',
      getEmployees: 'employee/getEmployees'
    }),
    ...mapMutations('core', ['setCurrentWorkshift','setCurrentLocation']),


    setupDraggable() {
      if  (document.getElementById("employees-box"))
        new Draggable(document.getElementById("employees-box"), {
          itemSelector: ".fc-event" /*,
          eventData: function(eventEl) {
            let event = {
              title: eventEl.innerText,
              duration: "01:00"
            };
            console.log(event);
            return event;
          } */
        });
    },


    createNew() {
      this.setCurrentWorkshift({name:'', color:''});
    },

    loadWorkshifts() {

      let buttons = ['delete','swap','send','onlyMe'];
      let icons = ['trash-alt','arrows-rotate','paper-plane','users'];

      for(let i = 0; i < buttons.length; i++)
        if (document.querySelector('.fc-' + buttons[i] + 'Button-button')) {
          document.querySelector('.fc-' + buttons[i] + 'Button-button').innerHTML = '<i class="fal fa-' + icons[i] + '"></i>';

        }

      /*
      document.querySelector('.fc-deleteButton-button').innerHTML = '<i class="fal fa-"></i>';
      document.querySelector('.fc-swapButton-button').innerHTML = '<i class="fal fa-arrows-rotate"></i>';
      document.querySelector('.fc-sendButton-button').innerHTML = '<i class="fal fa-paper-plane"></i>';
      document.querySelector('.fc-onlyMeButton-button').innerHTML = '<i class="fal fa-users"></i>';
      */

      if (comp.onlyMe === null) {
        comp.onlyMe = comp.currentUser?.level > 2;
      }

      let payload = {};

      if (!this.showInactive) {
        payload = {active:1};
      }

      this.getEmployees().then((list)=> {
        this.roles = [];
        list.forEach(emp => {
          emp.roles.forEach(role => {
            if (this.roles.findIndex(r => r.id == role.id) < 0)
              this.roles.push(role);
          });
        });
        console.log(this.roles);
        this.getWorkshifts(payload)
          .then(
            (items) => {
              comp.allItems = items;
              comp.events = []
              items.filter(el => el.employee.user?.id == (comp.onlyMe?comp.currentUser.id:el.employee.user?.id)).forEach(shift => {
                shift.display = 'block';
                shift.start = Date.parse(shift.startTime);
                shift.title = shift.employee.nickname + " (" + shift.role.name.substring(0,1).toUpperCase() + ")" ;
                shift.color = shift.role.color;
                if (this.onlyMe) shift.classNames = ['just-me'];
                //this.events.push({ id: shift.employee.nickname + Date.now(), title: shift.employee.nickname, start: Date.parse(shift.startTime) });
                comp.events.push(shift);
              });
              comp.calendarOptions.events = comp.events;
              comp.calendarOptions.editable = comp.currentUser.level < 3 && comp.currentUser?.name!='luca';
              comp.calendarOptions.droppable = comp.currentUser.level < 3 && comp.currentUser?.name!='luca';
              //console.log(this.workshifts);
              localStorage.setItem('workshifts',JSON.stringify(this.workshifts));
              comp.oldWorkshifts = comp.workshifts;
            }
          )
          .catch(

          );
      });


    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('workshifts_active',show);
      //this.loadWorkshifts();
    }

  },

  computed: {
    ...mapState('workshift', ['workshifts']),
    ...mapState('employee', ['employees']),
    ...mapState('core', ['currentUser','currentWorkshift','currentLocation'])
  }

}
</script>

<style scoped>

</style>