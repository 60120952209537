import axios from 'axios'

export default function axiosRequest ({ method, url, params = {}, options = {} }) {
  return new Promise((resolve, reject) => {
    if (method === 'get') {
      params = { params }
    }
    axios[method](url, params, options)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
