<template>
  <div id="tools"  class="box" :class="{hidden:!currentUser}">
    <div class="content">
      <aside>
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <h2 class="navbar-brand logo" href="#"><span class="brand-color-1">Risto</span><span class="brand-color-2">Matico</span></h2>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span><i class="fal fa-times"></i><i class="fal fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="main-menu navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" :class="{active: '/' == $route.fullPath }" aria-current="page" :href="'/'"><i class="fal fa-house"></i> Home</a>
                </li>
                <template v-for="item in menuItems.filter(el => el.userLevel >= currentUser?.level)">
                  <li class="nav-item">
                    <a class="nav-link" :href="item.url" :class="{active: item.url == $route.fullPath }"><i :class="item.icon"></i> {{ item.name }}</a>
                  </li>
                </template>
                <li class="nav-item" v-if="currentUser">
                  <a class="nav-link" @click="quit"><i :class="'fal fa-power-off'"></i> esci</a>
                </li>

              </ul>
            </div>
          </div>
        </nav>

      </aside>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import VueRouter from 'vue-router';

export default {
  name: "Locations",

  data() {
    return {
      menuItems: [
        /*{
          name: 'inventario',
          url: '/warehouse',
          icon: 'fal fa-warehouse-full',
          userLevel: 2
        },*/
        {
          name: 'turni di lavoro',
          url: '/workshifts',
          icon: 'fal fa-business-time',
          userLevel: 4
        }
      ],
      offLine: false,
      currentLocationString: '',
      tableClass: 'fa-chair',
      stateClass: this.currentUser == null? 'hidden':''
    }
  },

  created() {
    //setInterval(this.checkNetwork,10000);
  },

  methods: {
    ...mapMutations({
      setCurrentLocation: 'core/setCurrentLocation'
    }),

    ...mapActions({
      logout: 'core/logout'
    }),

    quit () {
      this.logout();
      this.$router.push('/logout?time='+ (new Date()).getTime());
    }

  },

  watch: {
    location: function(val) {
      switch (val?.isTakeaway) {
        case null:
        case 0:
          this.tableClass = 'fa-chair';
          break;
        case 1:
          this.tableClass = 'fa-shopping-bag';
          break;
        case 2:
          this.tableClass = 'fa-truck';
          break;
      }
    }
  },

  computed: {
    ...mapState('product', ['products']),
    ...mapState('core', ['currentUser','currentLocation']),
    ...mapState('location', ['location'])
  }


}
</script>

<style scoped>

</style>